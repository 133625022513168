.app-sidebar{
    background-color: #111c43 !important;
    color: #333335 !important;
}
.price_row{
    text-align: right !important;
}

.selected-row {
    background-color: lightblue; /* Define the style for selected rows */
  }